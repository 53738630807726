import Game from "./Game";
import { IGame } from "./types";

class GameFactory {
    public createGame(): IGame {
        return new Game();
    }
}

export default new GameFactory();
