import React from "react";
import styles from "./Hands.module.scss";
import classNames from "classnames";
import Hand from "./Hand/Hand";

interface Props {
    className?: string;
}

const subHands = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

const Hands = ({
    className,
}: Props) => {

    return (
        <div className={classNames(styles.root, className)}>
            {
                subHands.map(n => {
                    return (
                        <Hand key={n} index={n} />
                    );
                })
            }

            <Hand index={0}  className={styles.MainHand} />
        </div>
    );

};

export default Hands;
