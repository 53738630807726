import React from "react";
import WithCoinSize from "./WithCoinSize";
import styles from "./Hud.module.scss";
import classNames from "classnames";
import { HudProps } from "./types";
import MachineButton from "../casino/buttons/MachineButton";
import PokerDisplay from "../casino/display/PokerDisplay";
import NotPhone from "../screen/NotPhone";

const noop = () => undefined;

const Hud = ({
    className,
    coinSizeData,
}: HudProps) => {
    const [coinSize, cycleCoinSize] = coinSizeData;
    const coinSizeCaption = WithCoinSize.useCoinSizeCaption(coinSize);

    return (
        <div className={classNames(className, styles.root)}>
            <div className={classNames(styles.row, styles.buttonContainer)}>
                <NotPhone>
                    <MachineButton
                        className={styles.paytable}
                        caption="Paytable"
                        data-testid="hud-button-paytable"
                        onClick={noop}
                    />
                </NotPhone>

                <PokerDisplay
                    className={styles.balance}
                    caption="Balance"
                    value={180000}
                />

                <PokerDisplay
                    className={styles.bet}
                    caption="Bet"
                    value={5}
                />
                <PokerDisplay
                    className={styles.win}
                    caption="Win"
                    value={0}
                />

                <MachineButton

                    className={styles.coinSize}
                    caption={["Coin size", coinSizeCaption]}
                    data-testid="hud-button-coin-size"
                    onClick={cycleCoinSize}
                />
                <MachineButton
                    className={styles.totalHands}
                    caption={["Hands", "25"]}
                    data-testid="hud-button-hands"
                    disabled={true}
                    onClick={noop}
                />
                <MachineButton
                    className={styles.dealDraw}
                    caption="Deal"
                    kind="positive"
                    data-testid="hud-button-deal-draw"
                    disabled={true}
                    onClick={noop}
                />
            </div>
        </div>
    );
};

export default Hud;
