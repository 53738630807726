import React from "react";
import styles from "./MachineButton.module.scss";
import classNames from "classnames";
import BrushedMetal from "../containers/BrushedMetal";

type MachineButtonKind = "positive";

interface IMachineButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
    caption: string | [string, string];
    kind?: MachineButtonKind;
    onClick?: () => void;
}

const MachineButton = ({
    caption,
    className,
    kind,
    onClick,
    ...props
}: IMachineButtonProps) => {

    return (
        <BrushedMetal className={classNames(className, styles.root)}>
            <button onClick={onClick} {...props} className={classNames(styles.inner, kindToClassName(kind))}>
                {
                    isDoubleLine(caption) ? (
                        <>
                            {caption[0]}
                            <br />
                            {caption[1]}
                        </>
                    ) : caption
                }
            </button>
        </BrushedMetal>
    );
};

function kindToClassName(kind?: MachineButtonKind): string | undefined {
    switch (kind) {
        case 'positive': return styles.positive;
    }
}

function isDoubleLine(caption: string | [string, string]): caption is [string, string] {
    return typeof caption !== 'string';
}

export default MachineButton;
