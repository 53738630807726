import React from "react";
import styles from "./Hand.module.scss";
import classNames from "classnames";
import back from './back.svg';

interface Props {
    className?: string;
    index: number;
}

const cards = [1, 2, 3, 4, 5];

const Hand = ({
    className,
    index,
}: Props) => {

    if (index !== 0) {
        return <div />;
    }

    return (
        <div className={classNames(styles.root, className)}>
            {
                cards.map(n => {
                    return (
                        <img key={n} src={back} alt="" />
                    );
                })
            }
        </div>
    );
};

export default Hand;
