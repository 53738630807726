import { useCallback, useState } from "react";
import { CycleCoinSize } from "./types";

const COIN_SIZES = [1, 2, 5, 10, 25, 50, 100, 200, 500, 1000];

function useCoinSize(): [number, CycleCoinSize] {
    const [coinSizeIndex, setCoinSizeIndex] = useState(0);
    const coinSize = COIN_SIZES[coinSizeIndex];

    const cycleCoinSize: CycleCoinSize = useCallback(() => {
        const newIndex = coinSizeIndex + 1;

        if (newIndex >= COIN_SIZES.length) {
            setCoinSizeIndex(0);
        } else {
            setCoinSizeIndex(newIndex);
        }
    }, [coinSizeIndex]);

    return [coinSize, cycleCoinSize];
}

const WithCoinSize = {
    useCoinSize,
};

export default WithCoinSize;
