import React from "react";
import ScreenSizes from "./ScreenSizes";
import UseWidth from "./hooks/UseWidth";

interface Props {
    children: React.ReactNode;
}

const NotPhone: React.FC<Props> = ({children}) => {
    const screenWidth = UseWidth.useWidth();

    if (screenWidth >= ScreenSizes.getTabletMinimumWidth()) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

export default NotPhone;
