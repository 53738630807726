
function useCoinSizeCaption(coinSize: number): string {
    return `${coinSize}`;
}

const WithCoinSize = {
    useCoinSizeCaption,
};

export default WithCoinSize;
