import React from "react";
import styles from "./Header.module.scss";
import classNames from "classnames";
import { HeaderProps } from "./types";

const Header = ({
    className,
    name,
}: HeaderProps) => {

    return (
        <div className={classNames(className, styles.root)} data-testid="header-title">
            {name}
        </div>
    );
};

export default Header;
