import React from "react";
import styles from "./App.module.scss";
import WithGame from "./game/WithGame";
import Hands from "./hands/Hands";
import Header from "./header/Header";
import Hud from "./hud/Hud";

const App = () => {
    const [game, coinSizeData] = WithGame.useInstance();

    return (
        <div className={styles.root}>
            <Header className={styles.NaturalHeightRow} name={game.name} />
            <Hands className={styles.FillRemainingHeightRow} />
            <Hud className={styles.NaturalHeightRow} coinSizeData={coinSizeData} />
        </div>
    );
};

export default App;
