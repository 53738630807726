import { useState } from "react";
import GameFactory from "./GameFactory";
import { CoinSizeData, IGame, IGameView } from "./types";
import WithCoinSize from "./WithCoinSizeData";

function useInstance(): [IGameView, CoinSizeData] {
    return [
        useState<IGame>(gameProvider)[0],
        WithCoinSize.useCoinSize(),
    ];
}

function gameProvider(): IGame {
    return GameFactory.createGame();
}

const WithGame = {
    useInstance,
};

export default WithGame;
