import React from "react";
import styles from "./PokerDisplay.module.scss";
import classNames from "classnames";
import BrushedMetal from "../containers/BrushedMetal";

interface PokerDisplayProps {
    caption: string;
    className?: string;
    value: number;
}

const PokerDisplay = ({
    caption,
    className,
    value,
}: PokerDisplayProps) => {

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.caption}>{caption}</div>
            <BrushedMetal className={styles.metal}>
                <div className={styles.inner}>
                    {value}
                </div>
            </BrushedMetal>
        </div>
    );
};

export default PokerDisplay;
