import React from "react";
import styles from "./BrushedMetal.module.scss";
import classNames from "classnames";

const BrushedMetal: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
    ...props
}) => (
    <div className={classNames(className, styles.root)} {...props}>
        {children}
    </div>
);

export default BrushedMetal;
